import React from 'react';
import ReactDOM from 'react-dom/client';
import "./styles/index.css";
import store from "./redux/store";
import { Provider } from "react-redux";
import Connect from './pages/Connect';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Connect />
  </Provider>
);
