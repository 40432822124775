import React, { useEffect, useState } from "react";
import detectEthereumProvider from "@metamask/detect-provider";
import axios from "axios";
import "../styles/App.css"
import epicLogo from "../image/epic-games-logo.png";
import metamaskConnect from "../image/connectwallet.png";
import Web3 from "web3";
import { Spin } from "react-cssfx-loading";

function Connect() {
  const [hasProvider, setHasProvider] = useState(null)
  const initialState = { accounts: [], balance: "", chainId: "" }
  const [wallet, setWallet] = useState(initialState)
  const [provider, setProvider] = useState("");

  const [isConnecting, setIsConnecting] = useState(false)

  const [playfabId, setPlayfabId] = useState(null);
  const [successful, setSuccessful] = useState(false);

  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null);
  
  const queryParameters = new URLSearchParams(window.location.search);
  const code = queryParameters.get("code");
  const titleId = queryParameters.get("titleId")

  useEffect(() => {
    const refreshAccounts = (accounts: any) => {
      if (accounts.length > 0) {
        updateWallet(accounts)
      } else {
        setWallet(initialState)
      }
    }

    const refreshChain = (chainId: any) => {
      setWallet((wallet) => ({ ...wallet, chainId }))
    }

    const getProvider = async () => {
      const provider = await detectEthereumProvider({ silent: true })
      setProvider(provider)
      setHasProvider(Boolean(provider))

      if (provider) {
        const accounts = await window.ethereum.request(
          { method: 'eth_accounts' }
        )
        refreshAccounts(accounts)
        window.ethereum.on('accountsChanged', refreshAccounts)
        window.ethereum.on("chainChanged", refreshChain)
      }
    }

    getProvider()

    setIsLoading(false);

    return () => {
      window.ethereum?.removeListener('accountsChanged', refreshAccounts)
      window.ethereum?.removeListener("chainChanged", refreshChain)
    }
  }, [])

  useEffect(() => {
    console.log("TITLEID: ", titleId)
    validateEpicCode();
  }, [code])

  const handleSign = async () => {

    setIsLoading(true);

    const { ethereum } = window;

    let web3 = new Web3(ethereum);

    try {
      let nonceRequest = await axios({
        method: "post",
        url: "https://beanland-nft-server-s3pkopo4ra-uc.a.run.app/api/v1/wallets/nonce",
        data: {
          wallet: wallet.accounts[0],
        },
      });
      const input = nonceRequest.data;

      const signedData = await window.ethereum?.request({
        method: "personal_sign",
        params: [web3.utils.sha3(input.code), wallet.accounts[0]],
      });

      try {
        let verifySignatureRequest = await axios({
          method: "post",
          url: "https://beanland-nft-server-s3pkopo4ra-uc.a.run.app/api/v1/wallets/verify",
          data: {
            signature: signedData,
            wallet: wallet.accounts[0],
            playfabId: playfabId,
            titleId: titleId,
          },
        });

        setSuccessful(true)
        setIsLoading(false);
      } catch (verifyError) {
        setErrorMessage(verifyError.response.data.error);
        setIsLoading(false);
      }
    } catch (error) {
      console.log("ERROR: ", error)
      setIsLoading(false);
    }
  };

  const updateWallet = async (accounts: any) => {
    const balance = formatBalance(await window.ethereum.request({
      method: "eth_getBalance",
      params: [accounts[0], "latest"],
    }))
    const chainId = await window.ethereum.request({
      method: "eth_chainId",
    })
    setWallet({ accounts, balance, chainId })
  }

  const epicGamesSignIn = async () => {
    if (titleId) {
      const redirectURI = `https://www.epicgames.com/id/authorize?client_id=xyza7891HqiZPH9yRm8Kp9wwbqlKg2mO&redirect_uri=https://beanland-connect.beansnfts.io/?titleId=${titleId}&response_type=code&scope=basic_profile`;
      window.location.replace(redirectURI);
    } else {
      setErrorMessage("Error: Missing titleId")
    }
  }

  const validateEpicCode = async () => {
    if (code) {
      setIsLoading(true);
      console.log("CALLED CODE: ", code);

      const epicLoginURL =
        "https://beanland-nft-server-s3pkopo4ra-uc.a.run.app/api/v1/wallets/epic/login";

      try {
        const epicAuthResponse = await axios({
          method: "post",
          url: epicLoginURL,
          data: {
            code,
          },
        });
        
        const accessToken = epicAuthResponse.data.access_token;
        const openIdRequestURL =
          `https://${titleId}.playfabapi.com/Client/LoginWithOpenIdConnect`;
        
        if (titleId) {
          const data = {
            ConnectionId: "EpicOnlineServices",
            IdToken: accessToken,
            TitleId: titleId,
            CreateAccount: true,
          };

          try {
            const playfabResponse = await axios({
              method: "post",
              url: openIdRequestURL,
              data: data,
            });

            const responseData = playfabResponse.data.data;
            setPlayfabId(responseData.PlayFabId);
            setIsLoading(false);
          } catch (error) {
            console.log("ERROR: ", error);
            setErrorMessage(error.response.data.errorMessage)
            setIsLoading(false);
          }
        } else {
          setErrorMessage("Error connecting to Epic Games, invalid TitleId")
        }
      } catch (error) {
        console.log("ERROR: ", error);
        setIsLoading(false);  
      }
    }
  }

  const handleConnect = async () => {
    setIsLoading(true);
    setIsConnecting(true)
    await window.ethereum.request({
      method: "eth_requestAccounts",
    })
    .then((accounts:[]) => {
      updateWallet(accounts)
    })
    .catch((err:any) => {
      setErrorMessage(err.message)
    })
    setIsConnecting(false)
    setIsLoading(false);
  }

  const disableConnect = Boolean(wallet) && isConnecting

  return (
    <div className="container">
      <div className="titles-container">
        <center className="header-container">
          {errorMessage && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}
          <h1 className="beanland-title display-1">Beanland</h1>
          <h2 className="beanland-connect-text display-3">
            Connect Your Wallet
          </h2>
        </center>
      </div>
      <div className="connect-container">
        {playfabId === null && (
          <button
            type="button"
            className="btn epic-login-btn"
            onClick={epicGamesSignIn}
            disabled={isLoading}
          >
            {isLoading ? (
              <center>
                <Spin
                  color="#FFFFFF"
                  width="20px"
                  height="20px"
                  duration="3s"
                />
              </center>
            ) : (
              <div>
                <img className="epic-logo" src={epicLogo} alt="epic" />
                Sign in with Epic Games
              </div>
            )}
          </button>
        )}

        {window.ethereum?.isMetaMask &&
          wallet.accounts.length < 1 &&
          playfabId !== null && (
            <div className="metamask-connect-container">
              <img
                className="metamask-connect-btn"
                src={metamaskConnect}
                alt="connect-metamask"
                onClick={handleConnect}
              />
              <center>
                <p className="epic-connect-text">
                  Connnected to epic games account
                </p>
              </center>
            </div>
          )}

        {wallet.accounts.length >= 1 &&
          playfabId !== null &&
          successful !== true && (
            <button
              type="button"
              className="btn btn-outline-success connect-accounts-btn"
              disabled={disableConnect}
              onClick={handleSign}
            >
              Connect your accounts
            </button>
          )}

        {successful && (
          <div className="alert alert-success" role="alert">
            Successfully connected accounts!
          </div>
        )}
      </div>
    </div>
  );
}

export default Connect;

export const formatBalance = (rawBalance) => {
  const balance = (parseInt(rawBalance) / 1000000000000000000).toFixed(2);
  return balance;
};
